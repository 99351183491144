body {
    margin: 0;
    font-family: Roboto, sans-serif;
}

@media screen and (min-width: 900px) {
    .navbar-btn {
        display: inline-flex !important;
    }
}

@media screen and (max-width: 900px) {
    .navbar-sidebar-btn {
        display: inline-flex !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.json-to-table td {
    text-align: center;
    border: 1px solid #bebebe !important;
}

.grecaptcha-badge {
    visibility: hidden;
}
